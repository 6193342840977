import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import blurbImage1 from './g17487.png';


const styles = theme => ({
blurbImagePaper:{
    webkitClipPath: 'polygon(0 0, 100% 0%, 100% 100%, 0 100%)',
    clipPath: 'polygon(0 0, 100% 0%, 100% 100%, 0 100%)',
    padding:'5% 0 5% 0',

},
blurbImage:{

	[theme.breakpoints.down('xs')]:{
    	width:'90%',
    	},
    [theme.breakpoints.up('sm')]:{
    	width:'65%',
    	},
    [theme.breakpoints.up('lg')]:{
    	width:'45%',
    	},

},
blurbImageDiv:{
    
    background:'white',

},

  });


function BlurbImage(props){
	const { classes } = props;
	return(
        <div className={classes.blurbImageDiv}>
       <Paper color="primary" elevation={20} className={classes.blurbImagePaper}>
            <img alt="A classification system" src={blurbImage1} className={classes.blurbImage}/>
       </Paper>
       </div>
        );
}

export default withStyles(styles)(BlurbImage);