import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import landscape from './architecture-bridge-canada-414462.jpg';
import headshot from './headshot.jpg'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import {ThemeProvider } from '@material-ui/styles'


const styles = theme => ({


 rectangle: {

  webkitClipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
  clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
  position:'relative',
  color:'primary',
  width:'100%',
  margin:'5% 0 0 0',
  padding:'5% 0 5% 0',

  background:'white',
  },
  paper:{
    padding: theme.spacing(2),
    textAlign: 'center',
    align:'center',
  },
  headshot:{

    
     [theme.breakpoints.down('xs')]:{
    	width:'90%'},
    [theme.breakpoints.up('sm')]:{
    	width:'75%'},
    [theme.breakpoints.up('md')]:{
    	width:'60%'},
  },
  
    landscape:{

    
     [theme.breakpoints.down('xs')]:{
    	width:'95%'},
    [theme.breakpoints.up('sm')]:{
    	width:'90%'},
    [theme.breakpoints.up('md')]:{
    	width:'85%'},
  },
  teamDiv:
  {color:'white',
  background:'white',
  position:'relative',
  height:'80%',
  top:'5%',
  bottom:'0',
  }, 
  teamGridBottom:
  {
  margin:'5% 0 0 0',},
  teamGrid:
  {
  margin:'2% 0 0 0',},
   });


let theme = createMuiTheme({

});
theme = responsiveFontSizes(theme);

function Team(props){

	const { classes } = props;
	
	return(
        
          <div className={classes.rectangle} color="primary">
           <ThemeProvider theme={theme}>
            <Grid container className={classes.teamGrid}>
              <Grid item xs={12}>
                <Typography variant="h2" align="center" color="textPrimary" gutterBottom>
				Who We Are
                </Typography>
              </Grid>
              <Grid item xs={false} sm={2} lg={3} xl={4}/>
              <Grid item xs={12} sm={8} lg={6} xl={4}>
                <Paper className={classes.paper}>
                <img alt = "Vancouver at Night" src={landscape} className={classes.landscape}/>
                <Typography variant="h5" align="center" color="textSecondary" paragraph>
                 We are a team of specialists based out of Vancouver British Columbia specializing in research driven e-Learning. 
                </Typography>
                </Paper>
              </Grid>
              <Grid item xs={false} sm={2} lg={3} xl={4}/>
             

            
            </Grid>
            <Grid container className={classes.teamGridBottom}>
               <Grid item xs={false} sm={2} lg={3} xl={4}/>
              <Grid item xs={12} sm = {8} lg ={6} xl={4}>
                <Paper className={classes.paper}>
                <img alt="Francis McGee by the water" src={headshot} className={classes.headshot}/>
                <Typography variant="h5" align="center" color="textSecondary" paragraph>
                 The leader of Triangulum Studios, Francis McGee has a background in Engineering Physics from the University of British Columbia. Francis started Triangulum to fulfill his passion for improving public education and creating a more informed and inclusive society.
                </Typography>
                </Paper>
              </Grid>
              <Grid item xs={false} sm={2} lg={3} xl={4}/>
              </Grid>
               </ThemeProvider>
          </div>

        );
}

export default withStyles(styles)(Team);