import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import {ThemeProvider } from '@material-ui/styles'
import PropTypes from 'prop-types';

const styles = theme => ({

  headlineText:{
      color: 'white',
  	background:'transparent',
  	position:'relative',
  	verticalAlign:'center',




  },
  
    blurbDivInner:{
   

    
     [theme.breakpoints.down('xs')]:{
    	width:'90%',
    	paddingTop:'50px',
    	padding:'25px',
    	paddingBottom:'50px'},
    [theme.breakpoints.up('sm')]:{
    	width:'85%',
    	padding:'50px'},
    [theme.breakpoints.up('md')]:{
    	width:'80%',
    	padding:'50px'},
  
  position:'flex',
  display:'inline-block',
  padding:'50px',},
 
  });
  
  let theme = createMuiTheme({

});
theme = responsiveFontSizes(theme);

function Headline(props){
	const { classes } = props;
	return(


          <div className={classes.blurbDivInner}>
						<ThemeProvider theme={theme}>

            <Typography variant="h5" align="center" color="inherit" >
              At Triangulum we provide custom e-Learning solutions and consulting services to help build a better educated and informed world.
              </Typography>
			</ThemeProvider>
            
        
            

        </div>

        );
}

export default withStyles(styles)(Headline);