import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import {ThemeProvider } from '@material-ui/styles'





const styles = theme => ({

  blurbText:{
  	color: 'white',
  	background:'black',
  	position:'absolute',
  	top:'50%',
  	left:'50%',
  	transform:'translate(-50%, -50%)',
  	padding:'50px',
  
  },
  blurbDivInner:{
  [theme.breakpoints.down('xs')]:{
    	width:'95%',
    	padding:'20px'
    	},
    [theme.breakpoints.up('sm')]:{
    	width:'65%',
    	paddingTop:'20px',
    	paddingBottom:'20px',
    	},
    [theme.breakpoints.up('lg')]:{
    	width:'45%',
    	},
  position:'flex',
  display:'inline-block',

},
  });

let theme = createMuiTheme({



});
theme = responsiveFontSizes(theme);
function Blurb(props){
	const { classes } = props;
	return(
    
          
          <React.Fragment>
          	<div className={classes.blurbDivInner}>
          	<ThemeProvider theme={theme}>

            <Typography variant="h2" align="center" color="textPrimary"  gutterBottom>
              What we can do for you
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
We custom build systems to help educate your users and improve learning outcomes. Get in touch with us to see how we can support your business.
            </Typography>
            </ThemeProvider>
            </div>
		</React.Fragment>
            
          
       
        );
}

export default withStyles(styles)(Blurb);