import React, { Component } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ClassifierMenu from './Menu.js';

import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import {ThemeProvider } from '@material-ui/styles'



import logo from './Triangulum.png';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

const styles = theme => ({
  appBar: {
    position: 'fixed',
  },
  grow: {
  	textAlign:'center',
  	flexGrow: 1,
  },
  logo:{
  
  [theme.breakpoints.down('xs')]:{
    	height:'50px',
    	paddingBottom:'0%',
    	paddingTop:'10px'},
    [theme.breakpoints.up('sm')]:{
    	height:'50px',
    	paddingTop:'10px',
    	paddingBottom:'0%',},
    [theme.breakpoints.up('md')]:{
    	height:'50px',
    	paddingBottom:'0%',
    	paddingTop: '5px',},

  },
  
  classifierMenu:{
  
  [theme.breakpoints.down('xs')]:{
    	paddingBottom:'0%',
    	paddingTop:'20px'},
    [theme.breakpoints.up('sm')]:{
    	paddingTop:'10px',
    	paddingBottom:'0%',},
    [theme.breakpoints.up('md')]:{
    	paddingBottom:'0%',
    	paddingTop: '10px',},

  },
  title:{
  [theme.breakpoints.down('xs')]:{
    	paddingBottom:'0px',
    	},
    [theme.breakpoints.up('sm')]:{
    	
    	paddingBottom:'0px',
    	},
    [theme.breakpoints.up('md')]:{
    	
    	paddingBottom:'5px',
    	},
[theme.breakpoints.up('lg')]:{
    	
    	paddingBottom:'7px',
    	},

  verticalAlign:'middle',}
 
  
});


let theme = createMuiTheme({

});
theme = responsiveFontSizes(theme);
class Header extends React.Component{
	
	
	render(){
		const{ classes } = this.props;

		return(
			
			<div>
			<ThemeProvider theme={theme}>

			<AppBar position="static" color="primary" className={classes.appBar} >
			<Toolbar>
			<Grid container className={classes.teamGrid}>
			<Grid item xs={2}>
			<Link to="/">
            
						<img alt="Triangulum Studios Ltd. Logo" src={logo} className={classes.logo}/>
			</Link>
			</Grid>
			<Grid item xs={8} className={classes.title}>
				<Typography variant="h2" color="inherit" align="center" fontWeight="fontWeightBold" to="/">
					Triangulum Studios
				</Typography>
			</Grid>
			
			</Grid>
			</Toolbar>
			
		  </AppBar>
		  </ThemeProvider>
		  </div>
			
		);
	}
}

export default withStyles(styles)(Header);