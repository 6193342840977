import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import './App.css';
import Grid from '@material-ui/core/Grid';
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import Docs from './Docs.js'
import Blurb from './Blurb.js'
import { Parallax } from 'react-parallax';
import Team from './Team.js';
import Headline from './Headline.js';
import BlurbImage from './blurbImage.js';
import TryNow from './TryNow.js';
import Contact from './Contact.js';


import backdrop2 from './abundance-blur-bundle-167538 copy.jpg';


	import indigo from '@material-ui/core/colors/indigo';
	import blue from '@material-ui/core/colors/blue';
	import red from '@material-ui/core/colors/red';
	
	// All the following keys are optional.
	// We try our best to provide a great default value.
	const theme = createMuiTheme({
	  palette: {
		primary: blue,
		secondary: indigo,
		error: red,
		typography:{
			useNextVariants: true,
		},
		// Used by `getContrastText()` to maximize the contrast between the background and
		// the text.
		contrastThreshold: 3,
		// Used to shift a color's luminance by approximately
		// two indexes within its tonal palette.
		// E.g., shift from Red 500 to Red 300 or Red 700.
		tonalOffset: 0.2,
	  },
	});

const styles = theme => ({
  classifierDiv:{
 
  },
  headlineDiv:{

    [theme.breakpoints.down('xs')]:{
    	height:0,
    	paddingBottom:'5%',
    	paddingTop:'20%'},
    [theme.breakpoints.up('sm')]:{
    	height:0,
    	paddingTop:'20%',
    	paddingBottom:'5%',},
    [theme.breakpoints.up('md')]:{
    	height:0,
    	paddingBottom:'5%',
    	paddingTop: '10%',},

    	
	display:'flex',
	position:'relative',
	justifyContent:'center',
  },
    teamDiv:{
			height:'100%',
		display:'flex',
  	justifyContent:'center',
  	position:'relative',
  },
	bannerPaper:{
			height:'100%',
			paddingTop:'25px',
			paddingBottom:'25px',
			backgroundColor:'rgba(255,255,255,0.6)',
			position:'relative',
			
		
			},
			
		
  
      docDiv:{
			height:'100%',
		display:'flex',
  	justifyContent:'center',
  	position:'relative',
  },
  
  
  classifierText:{
  	color: 'white',
  	background:'white',
  	position:'absolute',
  	top:'50%',
  	width:'100%',
  	[theme.breakpoints.up('sm')]:{
  	width:'60%',
  	},
  	left:'50%',
  	transform:'translate(-50%, -50%)',
  	padding:'50px',
	},
	team:{
		margin:'500px',
	},
	parallaxTeam:{
		height:'100',
		width:'100%',
	},
	contact:{},
	
	blurbDiv:{
	justifyContent:'center',
  	position:'relative',
	},

  alignmentDiv: {
  minHeight:'50px',
  webkitClipPath: 'polygon(0 0, 100% 5%, 100% 96%, 0 100%)',
  clipPath: 'polygon(0 0, 100% 5%, 100% 96%, 0 100%)',
  background: 'blue',
  position:'relative',
  height:'100%',
  width:'100%',
  },
  bannerDiv:{
  height:50,
  width:'100%',
  clear:'both',
  },
  paddingDiv:{
  height:50,
  width:'100%',
  clear:'both',
  },
  
});






class App extends Component {

  render() {
    const { classes } = this.props;
	

	
		
    return (
		<MuiThemeProvider theme={theme}>
		
		  <div className="App">

			
			
			<Parallax blur={3} bgImage={backdrop2} strength={200} className={classes.parallaxTeam}>
				
				<div className={classes.bannerDiv}>
				
                </div>
                <div className={classes.paddingDiv}/>

                <Grid container>
                <Grid  xs={false} sm={1} lg={2} xl={3} item={true}/>
                <Grid xs={12} sm={10} lg={8} xl={6} item={true}>
                
                <Paper className={classes.bannerPaper}>
				<Headline className={classes.headline}/>
                </Paper>
                </Grid>
                <Grid xs={false} sm={1} lg={2} xl={3} item={true}/>
                </Grid>
                <div className={classes.paddingDiv}/>
			</Parallax>	
			<TryNow className={classes.trynow}/>	

				<div className={classes.blurbDiv}>
					
					

				<Blurb className={classes}/>	

					
					<BlurbImage/>
					
				</div>
				
				
							<div className={classes.docDiv}>
						<Docs className={classes.docText}/>
				</div>
				

					

						<div className={classes.teamDiv}>
							<Team className = {classes.team}/>
						</div>
				<div className={classes.classifierDiv}>
								<TryNow className={classes.trynow}/>

				<Contact className={classes.contact}/>
				</div>
				
				
		  </div>
	  </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(App);
