import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import './App.css';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { createMuiTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import PrivacyBlurb from './PrivacyBlurb.js'
import { Parallax } from 'react-parallax';
import Paper from '@material-ui/core/Paper';


import backdrop1 from './black-and-white-buildings-city-2255.jpg';


	import indigo from '@material-ui/core/colors/indigo';
	import blue from '@material-ui/core/colors/blue';

	import red from '@material-ui/core/colors/red';
	
	// All the following keys are optional.
	// We try our best to provide a great default value.
	const theme = createMuiTheme({
	  palette: {
		primary: blue,
		secondary: indigo,
		error: red,
		typography:{
			useNextVariants: true,
		},
		// Used by `getContrastText()` to maximize the contrast between the background and
		// the text.
		contrastThreshold: 3,
		// Used to shift a color's luminance by approximately
		// two indexes within its tonal palette.
		// E.g., shift from Red 500 to Red 300 or Red 700.
		tonalOffset: 0.2,
	  },
	});

const styles = theme => ({
  Tos:{
  	height: '100%',
  	display:'flex',
  	justifyContent:'center',
  	position:'relative',
  },
  headlineDiv:{

    [theme.breakpoints.down('xs')]:{
    	height:'40%',
    	paddingTop:'20%',
    	paddingBottom:'20%',},
    [theme.breakpoints.up('sm')]:{
    	height:'35%',
    	paddingTop:'15%',
    	paddingBottom:'20%',},
    [theme.breakpoints.up('md')]:{
    	height:'30%',
    	paddingTop:'10%',
    	paddingBottom:'20%',},

    	
	display:'flex',
	position:'relative',
	justifyContent:'center',
	width:'80%',
  },

  
  
});




class Privacy extends Component {

	
	

  render() {
    const { classes } = this.props;

		
    return (
		<MuiThemeProvider theme={theme}>
		
		<Parallax bgImage={backdrop1} strength={500}>
		<div className={classes.Tos}>
				  <div alignment='center' className={classes.headlineDiv}>
					<Paper>
						<PrivacyBlurb/>
					</Paper>
				  </div>
</div>
				</Parallax>
		  
	  </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(Privacy);
