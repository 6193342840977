import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import headshot from './architecture-bridge-canada-414462.jpg';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import {ThemeProvider } from '@material-ui/styles'
import Button from '@material-ui/core/Button';
import Countdown from 'react-countdown';
const styles = theme => ({


 rectangle: {

  webkitClipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
  clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
  position:'relative',
  color:'primary',
  width:'100%',
  margin:'5% 0 0 0',
  padding:'5% 0 5% 0',

  background:'white',
  },
  paper:{
    padding: theme.spacing(2),
    textAlign: 'center',
    align:'center',
  },
  headshot:{
    width:'80%',
  },
   button: {
    margin: theme.spacing(1),
  },
  teamDiv:
  {color:'white',
  background:'white',
  position:'relative',
  height:'80%',
  top:'5%',
  bottom:'0',
  }, 
  teamGridBottom:
  {
  margin:'5% 0 0 0',},
  teamGrid:
  {
  margin:'2% 0 0 0',},
   });

let theme = createMuiTheme({

});

const CompletedBlurb = () => <Typography>Launch imminent, check back soon!</Typography>

const renderer = ({days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <CompletedBlurb />;
  } else {
    // Render a countdown
    return <Typography variant="h3" align="center" color="textPrimary">Coming October 31!</Typography>;
  }
};
theme = responsiveFontSizes(theme);
function Contact(props){
	const { classes } = props;
	return(
        
          <div className={classes.rectangle} color="primary">
                    	<ThemeProvider theme={theme}>

            <Grid container className={classes.teamGrid}>
              <Grid item xs={12}>
                <Typography variant="h2" align="center" color="textPrimary" gutterBottom>
				Coming Soon!
                </Typography>
                <Typography variant="h4" align="center" color="textSecondary" gutterBottom>
				Our new e-Learning solution is almost here!
                </Typography>
              </Grid>
              
             

            
            </Grid>
           </ThemeProvider>
          </div>

        );
}

export default withStyles(styles)(Contact);