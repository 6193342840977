import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from './Header.js'
import { createMuiTheme } from '@material-ui/core/styles';
import Footer from './Footer.js';
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles';




import {BrowserRouter, Router, Route, useParams} from 'react-router-dom';
import './index.css';
import TOS from './Tos';
import App from './App';
import Privacy from './Privacy';
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';

	import indigo from '@material-ui/core/colors/indigo';
	import pink from '@material-ui/core/colors/pink';
	import blue from '@material-ui/core/colors/blue';
	import CookieConsent, { Cookies } from "react-cookie-consent";

	import red from '@material-ui/core/colors/red';
	import purple from '@material-ui/core/colors/purple';
	
	// All the following keys are optional.
	// We try our best to provide a great default value.
	const theme = createMuiTheme({
	  palette: {
		primary: blue,
		secondary: indigo,
		error: red,
		typography:{
			useNextVariants: true,
		},
		// Used by `getContrastText()` to maximize the contrast between the background and
		// the text.
		contrastThreshold: 3,
		// Used to shift a color's luminance by approximately
		// two indexes within its tonal palette.
		// E.g., shift from Red 500 to Red 300 or Red 700.
		tonalOffset: 0.2,
	  },
	});

const styles = theme => ({
  classifierDiv:{
  	height: 400,
  	display:'flex',
  	justifyContent:'center',
  	position:'relative',
  },
  headlineDiv:{

    [theme.breakpoints.down('xs')]:{
    	height:400,
    	paddingBottom:'10%',},
    [theme.breakpoints.up('sm')]:{
    	height:300,
    	paddingTop:50,
    	paddingBottom:0,},
    [theme.breakpoints.up('md')]:{
    	height:300,
    	paddingTop:80,},

    	
	display:'flex',
	position:'relative',
	justifyContent:'center',
  },
    teamDiv:{
			height:'100%',
		display:'flex',
  	justifyContent:'center',
  	position:'relative',
  },
  
  classifierText:{
  	color: 'white',
  	background:'white',
  	position:'absolute',
  	top:'50%',
  	width:'100%',
  	[theme.breakpoints.up('sm')]:{
  	width:'60%',
  	},
  	left:'50%',
  	transform:'translate(-50%, -50%)',
  	padding:'50px',
	},
	team:{
		margin:'500px',
	},
	parallaxTeam:{
		height:'100',
		width:'100%',
	},
  alignmentDiv: {
  minHeight:'50px',
  webkitClipPath: 'polygon(0 0, 100% 5%, 100% 96%, 0 100%)',
  clipPath: 'polygon(0 0, 100% 5%, 100% 96%, 0 100%)',
  background: 'blue',
  position:'relative',
  height:'100%',
  width:'100%',
  },
});
class Routes extends Component {

	constructor(props){
		super(props);

                    this.state = {
					};
	}

    render() {
                    const { classes } = this.props;
                    const TITLE = 'Triangulum Studios Ltd.'
                    
		
        return (

                <MuiThemeProvider theme={theme}>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
				
				
				<Helmet>
         			 <title>{ TITLE }</title>
        		</Helmet>
				
				<CssBaseline />

                    <BrowserRouter >
                    	<div>
				 <Header className={classes.header}/>
				 		<Route exact path="/" component={App}/>
                        <Route path="/tos" component={TOS} />
                        <Route path="/privacy" component={Privacy} />
                        <CookieConsent>
    This website uses cookies to enhance the user experience.
</CookieConsent>
                        <Footer className={classes.footer}/>
						</div>
                    </BrowserRouter>
			

                </MuiThemeProvider>
        )
    }
}

export default  withStyles(styles)(Routes);

