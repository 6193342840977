import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from 'react-router-dom'

const styles = theme => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    bottom: 0,
	flexGrow: 1,
	},	
});

function Footer(props){
	const { classes} = props;
	
	return(
		<footer className={classes.footer}>

        	<Typography variant="body1" align="center" color="textSecondary" component="p">
		©2015-2021 Triangulum Studios Ltd.        	
</Typography>
<Typography variant="body1" align="center" color="textSecondary" component="p">
		<Link to="/tos">
          TOS
          
        </Link>
</Typography>
<Typography variant="body1" align="center" color="textSecondary" component="p">
        <Link to="/privacy" >
          Privacy Policy
        </Link>       	
</Typography>

      	</footer>
      	);
	
	}
	
export default withStyles(styles)(Footer);