import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import {ThemeProvider } from '@material-ui/styles'

const styles = theme => ({


 rectangle: {


  position:'relative',
  color:'primary',
  width:'100%',
  margin:'0 0 0 0',
  padding:'5% 0 10% 0',

  background:'white',
  },
  paper:{
    padding: theme.spacing(2),
    textAlign: 'center',
    align:'center',
  },
  headshot:{
    width:'80%',
  },
    });
    
    
let theme = createMuiTheme({

});
theme = responsiveFontSizes(theme);

function Docs(props){
	const { classes } = props;

	return(
        
          <div className={classes.rectangle} color="primary">
          <ThemeProvider theme={theme}>
            <Grid container className={classes.teamGrid}>
              <Grid item xs={12}>
                <Typography variant="h2" align="center" color="textPrimary" gutterBottom>
					What's Next
                </Typography>
              </Grid>
              <Grid item xs={false} sm={1} lg={2} xl={3}/>
              <Grid item xs={12} sm={10} lg={8} xl={6}>
                <Paper className={classes.paper}>
                <Typography variant="h5" align="center" color="textSecondary" paragraph>
              We are putting the finishing touches on a new learning system which will allow content creators to embed powerful educational tools into their existing website and branding.
                </Typography>
           
                </Paper>
              </Grid>
              <Grid item xs={false} sm={1} lg={2} xl={3}/>
            </Grid>
            </ThemeProvider>
          </div>

        );
}

export default withStyles(styles)(Docs);